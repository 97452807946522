import styles from "./Documents/Documents.module.scss";
import { ReactSVG } from "react-svg";
import warning from "../../assets/icons/warning-gray.svg";
import clsx from "clsx";
import dayjs from "dayjs";
import React from "react";
import Tooltip from "../../components/UI/Tooltip/Tooltip";
import CustomButton from "../../components/UI/CustomButton/CustomButton";
import {pluralizeDay} from "../../utils/tariffService";

const OrderRenewalBlock = ({ orderRenewal, flightToBookingExpiresAt, tariff, paymentClick, orderRenewalDay, issuing}) => {

    return (
        <div
            className={clsx(styles.documents, {
                [styles.documents__issuing]: issuing,
            })}
        >
            <h3 className={styles.documents__title}>Продление брони</h3>
            {orderRenewal === true && flightToBookingExpiresAt != null ? (
                <>
                    <div className={styles.bookingSuccess__top_buttons}>
                        <p className={styles.documents__text}>

                        </p>
                        <CustomButton
                            classes={styles.payment__btn}
                            onClick={paymentClick}
                            primary
                        >
                            Продлить до {dayjs(orderRenewalDay)
                            .utc(true)
                            .format("DD.MM.YYYY HH:mm")} <Tooltip
                            content={"После оплаты заказа срок брони будет автоматически продлен на " + (tariff.term_start / 24) +  (
                                tariff.term_start !== tariff.term_end ? (" - " + tariff.term_end / 24) : ' '
                            ) + " " + pluralizeDay(tariff.term_end / 24)}
                            children={<ReactSVG src={warning}/>}
                        />
                        </CustomButton>
                    </div>
                </>
            ) : null
            }
        </div>
    );
};

export default OrderRenewalBlock;
